import { RawValueWithUnit, ValueWithUnit } from '@/types';

export const filterUntrustedList = <T>(
  x: (T | undefined)[] | undefined
): T[] => {
  if (!x) {
    return [];
  }
  return x.filter((n): n is T => n !== undefined);
};

export const parseRawValueWithUnit = (
  raw?: RawValueWithUnit
): ValueWithUnit => {
  if (!raw) {
    return { value: 'N/A' };
  }
  return { value: raw.Value.toLocaleString(), unit: raw?.Unit };
};

export function capitalizeWords(words: string): string {
  return words
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export const encodeSpecialCharacters = (inputString: string) => {
  // Define a mapping of special characters to HTML entities
  const ENTITY_MAP: { [key: string]: string } = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&apos;',
    '’': '&#8217;',
    // Add more mappings as needed
  };

  // Replace each special character with its HTML entity
  return inputString.replace(/[&<>"'’]/g, (match) => {
    return ENTITY_MAP[match];
  });
};

export const formatDate = (
  date: Date,
  variant: 'numeric' | 'short',
  location?: string
): string => {
  const format: Intl.DateTimeFormatOptions =
    variant === 'numeric'
      ? {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          timeZone: location,
          timeZoneName: 'short',
          hour12: false,
        }
      : {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        };

  const dateFormatter = new Intl.DateTimeFormat('en-US', format);

  return dateFormatter.format(date);
};

/** Sometimes if you use double quotes in text in the WordPress Block Editor, you'll get
 * curly quotes instead of straight quotes.
 * Even then, the closing quote might be a different character than the opening quote.
 * This function normalizes those quotes so they are the same character.
 */
export const normalizeQuotes = (text: string): string =>
  text.replace(/[“”‘’″`ʼ]/g, '"');

export const slugify = (s: string) => s.replaceAll(' ', '-').toLowerCase();
