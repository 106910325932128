import { Skeleton } from '@/components/atoms/Skeleton';

export interface BaseProductListingLinkProps {
  isColumnLayout?: boolean;
}

export const LoadingProductListingLink: React.FC<
  BaseProductListingLinkProps
> = ({ isColumnLayout }) => {
  return (
    <div
      className={`flex ${isColumnLayout ? 'flex-col' : ''} flex-grow rounded-small border border-neutral-200`}
    >
      <div
        className={`flex ${isColumnLayout ? 'h-[40%] w-full' : 'h-full w-[40%]'} animate-pulse bg-loading-gradient`}
      />
      <div className="flex flex-grow flex-col justify-between p-l">
        <div className="flex flex-col gap-xl pt-xl">
          <div className="flex h-[32px] w-full animate-pulse rounded-small bg-loading-gradient" />
          <div className="flex h-[32px] w-full animate-pulse rounded-small bg-loading-gradient" />
        </div>
        <div className="flex h-[48px] w-full rounded-small bg-loading-gradient" />
      </div>
    </div>
  );
};
export const VDPComponentLoadingCard = () => {
  return (
    <div className="flex flex-col gap-s">
      <Skeleton className="h-[40px] rounded-small p-xl" />
      <Skeleton className="flex h-[150px] w-full rounded-small ml:h-[200px]" />
      <Skeleton className="h-[40px] rounded-small p-xl" />
      <Skeleton className="flex h-[150px] w-full rounded-small ml:h-[200px]" />
    </div>
  );
};
