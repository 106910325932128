import { Direction } from '@/types/enums';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import React from 'react';

export interface CarouselButtonProps
  extends Pick<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    'onClick' | 'disabled' | 'onMouseEnter' | 'onMouseLeave'
  > {
  direction: Direction;
  className?: string;
}

export const CarouselDirectionButton: React.FC<CarouselButtonProps> = ({
  direction,
  className = '',
  ...props
}) => {
  return (
    <button
      className={`flex h-[40px] w-[40px] items-center justify-center rounded-full border-[1px] border-neutral-0 bg-white/70 shadow-[0px_1.667px_3.33px_0px_rgba(64,64,75,0.1)] ${className}`}
      type="button"
      aria-label={
        direction === Direction.Right ? 'Select next' : 'Select previous'
      }
      {...props}
    >
      {direction === Direction.Right ? (
        <ChevronRightIcon className="text-neutral-800" width={20} height={20} />
      ) : (
        <ChevronLeftIcon className="text-neutral-800" width={20} height={20} />
      )}
    </button>
  );
};
